<template>
  <div class="mt-10 mb-5 px-2 sm:px-6 lg:px-2">
    <div class="px-4 sm:px-6 lg:px-8" style="margin: auto; max-width: 1200px">
      <!-- Header -->
      <div class="flex">
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            <div class="">Bot Templates</div>
            <div class="text-xs pt-1 font-normal text-gray-500">Reduce average response time and delight customers using chatbots.</div>
          </div>
        </div>
      </div>

      <!-- Loader -->
      <div class="mt-6 rounded-lg border overflow-hidden bg-white" v-if="loading" style="padding: 30vh 0px">
        <div v-loading="true" class="w-full h-14 mt-6"></div>
      </div>

      <!-- Template List -->
      <el-row v-else class="mt-4" :gutter="20">
        <el-col :span="24" v-for="(template, index) in templateList" :key="index">
          <div class="mb-4 border bg-white rounded-lg hover:bg-white hover:shadow-sm hover:border-gray-300 cursor-pointer">
            <div class="px-5 py-4 flex">
              <div class="flex-1">
                <div class="flex">
                  <div class="flex-1 mt-2 font-medium text-gray-700">{{ template.title }}</div>
                  <div class="flex-none">
                    <!-- Enable Disable Switch -->
                    <template v-if="!template.comingSoon"> <el-switch @change="toggleBotActivation(template)" v-model="template.isActive" /> </template>
                    <!-- Coming Soon -->
                    <template v-else>
                      <div class="py-1 px-2 bg-gray-200 border-t rounded-full text-xs text-gray-500">Coming Soon</div>
                    </template>
                  </div>
                </div>

                <!-- Description -->
                <div class="mb-1 mt-2 text-sm text-gray-500">{{ template.description }}</div>

                <!-- Platforms -->
                <div class="mt-2" v-if="template.platforms">
                  <div class="flex">
                    <div class="flex-1 text-xs text-gray-700 flex">
                      <div class="bg-pink-200 mr-2 p-1 rounded-full px-2" v-for="(platform, index) in template.platforms" :key="index">
                        {{ platform }}
                      </div>
                    </div>
                    <div class="text-xs text-gray-700 flex" v-if="template.isActive">
                      <el-button class="" size="mini" type="primary" @click="onShowBotPreviewUserSelectorDialog(template)" plain>Preview </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <BotPreviewUserSelectorDialog @startBotPreview="onShowBotPreviewDialog" ref="botPreviewUserSelectorDialog"></BotPreviewUserSelectorDialog>
    <BotPreviewDialog ref="botPreviewDialog"></BotPreviewDialog>
  </div>
</template>

<script>
import botTemplateListComponent from './botTemplateListComponent';
export default botTemplateListComponent;
</script>

<!-- <style lang="scss" src="./chatBox.scss"></style> -->
