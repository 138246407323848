import customerService from './../../../../../services/customerService';
import * as _ from 'lodash';

export default {
  name: 'botPreview',

  components: {},

  data() {
    return {
      visible: false,
      step: 'customer-select',

      // Customer Select
      customerSearchInput: {
        searchType: 'mobile',
        customerId: ''
      },
      selectedCustomer: null,

      // Bot Execution.
      botId: null,
      currentStepId: null,
      messages: []
    };
  },

  methods: {
    async fetchCustomerListForPreview(query, cb) {
      try {
        let params = {
          search: query,
          search_type: this.customerSearchInput.searchType,
          company_id: 'test_company_id_123'
        };

        customerService
          .searchCustomer(params)
          .then((result) => {
            let parsedData = [];
            for (let i = 0; i < result.data.length; i++) {
              parsedData.push({
                label: result.data[i].id,
                value: result.data[i][this.customerSearchInput.searchType]
              });
            }
            this.customerSearchInput.list = result.data;

            cb(parsedData);
          })
          .catch((err) => {
            console.log('Failed to search customer for bot preview.', err);
            this.errorToast('Failed to search customer. Please contact support.');
            cb([]);
          });
      } catch (err) {
        console.log('Failed to search customer for bot preview.', err);
        this.errorToast('Failed to search customer. Please contact support.');
      }
    },

    async onPreviewUserSelected(item) {
      let customer = _.find(this.customerSearchInput.list, (o) => {
        return o.id == item.label;
      });
      this.selectedCustomer = customer;
    },

    startPreview() {
      this.$emit('startBotPreview', {
        customerId: this.selectedCustomer.id,
        botId: this.botId
      });
      this.visible = false;
    },

    async showUserSelectDialog(botId) {
      this.botId = botId;
      this.visible = true;
    }
  },

  mounted() {}
};
