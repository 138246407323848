import botTemplates from './../botTemplates';
import botSettingService from './../../../../services/botSettingsService';
import BotPreviewUserSelectorDialog from './../botPreview/botPreviewUserSelector/botPreviewUserSelector';
import BotPreviewDialog from './../botPreview/botPreviewDialog/botPreviewDialog';

export default {
  name: 'BotTemplateListComponent',

  components: {
    BotPreviewUserSelectorDialog,
    BotPreviewDialog
  },

  data() {
    return {
      loading: true,

      templateList: JSON.parse(JSON.stringify(botTemplates))
    };
  },

  methods: {
    async toggleBotActivation(template) {
      try {
        if (template.isActive) {
          const params = { bot_template: template.name, company_id: this.$store.state.token.companyId };
          await botSettingService.enableBotTemplate(params);
          this.successToast('Bot is active now.');
        } else {
          const params = { bot_template: template.name, company_id: this.$store.state.token.companyId };
          await botSettingService.disableBotTemplate(params);
          this.successToast('Bot disabled.');
        }
      } catch (err) {
        this.errorToast('Failed to enable/disable bot. Please contact support.');
        console.error('Failed to enable/disable bot.', err);
      }
    },

    async getActiveTemplates() {
      try {
        let result = await botSettingService.getActiveBotTemplates({
          company_id: this.$store.state.token.companyId
        });

        for (let i = 0; i < this.templateList.length; i++) {
          let template = this.templateList[i];
          template.isActive = false;
          if (result.data[template.name]) {
            template.isActive = true;
            template.botId = result.data[template.name]._id;
          }
        }

        this.loading = false;
      } catch (err) {
        // console.error('Failed to fetch agent list', err);
      }
    },

    async onShowBotPreviewDialog(data) {
      this.$refs.botPreviewDialog.showBotPreviewDialog(data.botId, data.customerId);
    },

    async onShowBotPreviewUserSelectorDialog(template) {
      let botId = template.botId;
      this.$refs.botPreviewUserSelectorDialog.showUserSelectDialog(botId);
    }
  },

  mounted() {
    this.getActiveTemplates();
  }
};
