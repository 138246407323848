<template>
  <el-dialog append-to-body custom-class="botPreviewDialog" :visible.sync="visible" width="900px" center title="Preview Bot" :destroy-on-close="true">
    <!-- Execution List -->
    <div class="message-list" v-for="(execution, index) in executionList" :key="index">
      <!-- Print All Messages First -->
      <template v-if="execution.data.details.message">
        <!-- Message Item -->
        <div class="flex-1 message-item-container" :class="{ 'from-customer': execution.source == 'customer' }" v-for="(message, msgIndex) in execution.data.details.message" :key="msgIndex">
          <div class="message-item my-2 py-3" style="max-width: 50%">
            <!-- Text -->
            <div class="px-3">
              {{ message.text }}
            </div>

            <!-- Buttons -->
            <div class="mt-2 mx-3 mb-3" v-if="message.buttons">
              <el-button :disabled="botExited" class="w-full" v-for="(button, btnIndex) in message.buttons" :key="btnIndex" type="info" size="mini" :plain="true" @click="onMessageButtonClick(execution, button)">
                {{ button.text }}
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- customer Reply input -->
    <div class="p-3 border-t bg-gray-50 flex" v-if="!botExited">
      <el-input v-model="customerReply" placeholder="Customer Reply" class="flex-1" />
      <div class="pl-3">
        <el-button type="primary" @click="onSendMessage" :disabled="customerReply.length == 0">Send</el-button>
      </div>
    </div>
    <div class="mt-2 p-3 bg-red-100 text-center w-full" v-else>Bot Exited</div>
  </el-dialog>
</template>

<script>
import botPreviewDialogComponent from './botPreviewDialogComponent';
export default botPreviewDialogComponent;
</script>

<style lang="scss" src="./botPreviewDialog.scss"></style>
