import botSettingService from './../../../../../services/botSettingsService';

export default {
  name: 'botPreview',

  components: {},

  data() {
    return {
      visible: false,
      botId: null,
      customerId: null,

      executionList: [],
      customerReply: '',

      botExited: false
    };
  },

  methods: {
    async showBotPreviewDialog(botId, customerId) {
      try {
        this.visible = true;
        this.botId = botId;
        this.customerId = customerId;
        this.executionList = [];
        this.botExited = false;
        this.customerReply = '';

        const params = {
          bot_id: this.botId,
          customer_id: this.customerId,
          company_id: 'test_company_id_123'
        };

        const result = await botSettingService.startBotPreview(params);

        this.executionList.push({
          source: 'agent',
          data: result.data
        });

        this.checkForBotExit();
      } catch (err) {
        console.log('Failed to start bot preview', err);
        this.errorToast('Failed to start bot preview. Please contact support.');
      }
    },

    async onMessageButtonClick(execution, button) {
      try {
        this.executionList.push({
          source: 'customer',
          data: {
            details: {
              message: [{ text: button.text }]
            }
          }
        });

        const params = {
          bot_id: this.botId,
          bot_step_id: execution.data.botStepId,
          customer_id: this.customerId,
          company_id: 'test_company_id_123',
          message_text: button.text
        };

        const result = await botSettingService.executeBotPreview(params);
        this.executionList.push({
          source: 'agent',
          data: result.data
        });

        this.checkForBotExit();
      } catch (err) {
        console.log('Failed to send message to bot preview', err);
        this.errorToast('Failed to send message to bot preview. Please contact support.');
      }
    },

    async checkForBotExit() {
      if (this.executionList.length === 0) return;

      const execution = this.executionList[this.executionList.length - 1];
      if (execution.data.type === 'exit') {
        this.botExited = true;
      }
    },

    async onSendMessage() {
      try {
        const execution = this.executionList[this.executionList.length - 1];

        const params = {
          bot_id: this.botId,
          bot_step_id: execution.data.botStepId,
          customer_id: this.customerId,
          company_id: 'test_company_id_123',
          message_text: this.customerReply
        };

        this.executionList.push({
          source: 'customer',
          data: {
            details: {
              message: [{ text: this.customerReply }]
            }
          }
        });

        console.log('Call Api =>', params);

        const result = await botSettingService.executeBotPreview(params);
        console.log('result is', result);

        this.executionList.push({
          source: 'agent',
          data: result.data
        });

        this.customerReply = '';
        this.checkForBotExit();
      } catch (err) {
        console.log('Failed to send message to bot preview', err);
        this.errorToast('Failed to send message to bot preview. Please contact support.');
      }
    }
  },

  mounted() {
    // this.botId = 'test_company_id_123_SubscribeBotTemplate';
    // this.companyId = '9a6ce245-a03d-4a7b-8042-1a81b7d685ed';
    // this.showBotPreviewDialog(this.botId, this.customerId);
  }
};
