export default [
  {
    name: 'UnsubscribeBotTemplate',
    title: 'Unsubscribe Bot',
    description: 'Allow customers to unsubscribe from campaign messages by typing "Unsubscribe" or "Stop".',
    isActive: true,
    comingSoon: false,
    platforms: ['Works on Any Platform']
  },
  {
    name: 'SubscribeBotTemplate',
    title: 'Re-subscribe Bot',
    description: 'Allow customers to re-subscribe after they have unsubscribed on whatsapp by typing "Subscribe" or "Stop".',
    isActive: true,
    comingSoon: false,
    platforms: ['Works on Any Platform']
  },
  // {
  //   name: 'WelcomeBotTemplate',
  //   title: 'Welcome Bot',
  //   description: 'Welcome Bot',
  //   isActive: false,
  //   comingSoon: true,
  //   platforms: ['Shopify', 'Woo-Commerce']
  // },
  {
    name: 'COD to Prepaid & COD Confirmation',
    title: 'COD to Prepaid & COD Confirmation',
    description: 'Enable customers to make payment or give confirmation for COD orders.',
    isActive: false,
    comingSoon: true,
    platforms: ['Shopify']
  },
  {
    name: 'Reordering Campaign',
    title: 'Reordering Campaign',
    description: 'Automatically send customers notifications for feedback and reordering in Whatsapp.',
    isActive: false,
    comingSoon: true,
    platforms: ['Shopify']
  },
  {
    name: 'Shipping Notifications Campaign',
    title: 'Shipping Notifications Campaign',
    description: 'Automatically send customers shipping notifications in Whatsapp.',
    isActive: false,
    comingSoon: true,
    platforms: ['Shopify']
  },
  {
    name: 'Review Campaign',
    title: 'Review Campaign',
    description: 'Automatically send customers for feedback and collect reviews.',
    isActive: false,
    comingSoon: true,
    platforms: ['Shopify']
  }
];
