<template>
  <el-dialog append-to-body custom-class="botPreviewUserSelectDialog" :visible.sync="visible" width="900px" center title="Preview Bot" :destroy-on-close="true">
    <!-- Customer Selection -->
    <div v-if="step == 'customer-select'">
      <div class="w-full p-3">
        <!-- Search User -->
        <div class="inputDetailOfUser">
          <div class="py-4">Select Customer For Bot Preview</div>
          <el-form @submit.native.prevent>
            <!-- // Query Search  -->
            <el-form-item required>
              <el-autocomplete v-model="customerSearchInput.customerId" class="selectAttribute w-full" :fetch-suggestions="fetchCustomerListForPreview" placeholder="Search Customer For Preview" @select="onPreviewUserSelected">
                <el-select v-model="customerSearchInput.searchType" slot="prepend" placeholder="Select user property to search by">
                  <el-option label="Email" value="email"></el-option>
                  <el-option label="Mobile" value="mobile"></el-option>
                  <el-option label="Name" value="name"></el-option>
                  <el-option label="Growlytics ID" value="id"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
          </el-form>

          <!-- Customer Details Block -->
          <div class="w-full border rounded-md overflow-hidden" v-if="selectedCustomer">
            <div class="p-2 text-left w-full bg-gray-50 border-b">Customer For Preview</div>
            <div class="text-left p-3">
              <div class="py-1"><i class="fa-duotone fa-user mr-2"></i>{{ selectedCustomer.name }}</div>
              <div class="py-1"><i class="fa-duotone fa-envelope mr-2"></i>{{ selectedCustomer.email }}</div>
              <div class="py-1"><i class="fa-duotone fa-phone mr-2"></i>{{ selectedCustomer.mobile }}</div>
            </div>
          </div>

          <div class="mt-4" v-if="selectedCustomer">
            <el-button type="primary" @click="startPreview">Preview Bot</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import botPreviewUserSelectorComponent from './botPreviewUserSelectorComponent';
export default botPreviewUserSelectorComponent;
</script>

<style lang="scss" scoped>
.botPreviewUserSelectDialog {
  .inputDetailOfUser {
    margin: auto;
    max-width: 500px;
    text-align: center;

    .selectAttribute {
      width: 100%;

      .el-input-group__prepend {
        width: 23%;

        .el-input__inner {
          width: 65%;
        }
      }

      .el-input__inner {
        width: 75%;
      }
    }
  }
}
</style>
