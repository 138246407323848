const axios = require('axios');
const { baseUrlNew, chatServiceUrl, chatServiceApiServerlessUrl, buildAuthHeaders } = require('./settings.js');

const getList = function (params) {
  return axios.post(baseUrlNew + `customer/list/v2`, params, {
    headers: buildAuthHeaders()
  });
};

const getDetails = function (params) {
  return axios.post(baseUrlNew + `customer/detail`, params, {
    headers: buildAuthHeaders()
  });
};

const getPastOrders = function (params) {
  return axios.post(chatServiceUrl + `/customer/orders`, params, {
    headers: buildAuthHeaders()
  });
};

const searchCustomer = function (params) {
  return axios.post(chatServiceApiServerlessUrl + `/customer/search`, params, {
    headers: buildAuthHeaders()
  });
};

module.exports = {
  searchCustomer,
  getList,
  getDetails,
  getPastOrders
};
